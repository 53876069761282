<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Academic Class Student ({{academic_class.title}})</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Academic Class Student
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="openEnrollNow()"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Enroll Now
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th><strong>Full Name</strong></th>
                  <th><strong>Email</strong></th>

                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>
                <template>
                  <tr v-if="academic_class_students.length>0" v-for="(item, index) in academic_class_students" :key="index">
                    <td>
                      <a  class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        <i class="fa fa-sort"></i> {{ item.full_name }}
                      </a>
                    </td>
                    <td>
                        <span class="text-dark-65">
                          {{ item.email }}
                        </span>
                    </td>
                    <td class="pr-0 text-left">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click="deleteAcademicClassStudent(item.id)">
                                  <span class="navi-icon">
                                      <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                  </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="academic_class_students.length==0">
                    <td colspan="3" class="text-center font-weight-medium">No Data Found</td>
                  </tr>
                </template>
              </table>
            </div>
          </div>
        </div>
      </div>
      <v-dialog v-model="enrollDialog" persistent max-width="600px">

        <v-card>
          <v-card-title>
            <span class="text-h5">Enroll now</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete item-text="full_name" item-value="id"
                                  v-model="enrol.user_id" :items="students" :search-input.sync="studentSearch" :error="$v.enrol.user_id.$error"
                                  outlined dense label="Search Student Name*"  clearable >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.full_name }}
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"></v-list-item-content>
                      </template>
                      <template v-else>

                        <v-list-item-content>
                          <v-list-item-title v-html="data.item.full_name"></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:label>
                      Select Student
                    </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.enrol.user_id.$error">Select User</span>

                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="mr-2" text dark
                medium
                @click="closeEnrollNow">
              Close
            </v-btn>
            <v-btn
                class="btn btn-primary"
                medium
                text
                @click="enrollNow()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>
<script>
import AcademicClassService from "@/services/academic-class/AcademicClassService";
import UserService from "@/services/user/UserService";
import {required} from "vuelidate/lib/validators";
const user=new UserService();
const academicClass = new AcademicClassService();
export default {
  validations: {
    enrol:{
      user_id:{required}
    }
  },
  data(){
    return{
      loading:false,
      academic_class_students:[],
      enrollDialog:false,
      studentSearch:'',
      students:[],
      enrol:{
        academic_class_id:'',
        user_id:'',
      },
      academic_class:{},

    }
  },
  methods:{
    getAcademicClassStudents(){
      academicClass
          .getAcademicClassStudents(this.classId)
          .then(response => {
            this.academic_class_students=response.data.academic_class_student;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    deleteAcademicClassStudent(userId)
    {
      academicClass
      .removeAcademicClassStudents(this.classId,userId)
          .then(response => {
            this.getAcademicClassStudents();
            this.$snotify.success("Student Removed Successfully");
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    openEnrollNow() {
      this.enrollDialog=true;
      this.enrol.academic_class_id=this.classId;
      this.getAcademicClassStudents();
    },
    closeEnrollNow(){
      this.enrol={
        academic_class_id:'',
      };
      this.enrollDialog=false;
      this.$v.$reset()
      this.studentSearch='';
    },
    classDetail()
    {
      academicClass
      .show(this.classId)
          .then(response => {
            this.academic_class=response.data.class
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    enrollNow() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        academicClass
            .enrolNow(this.enrol)
            .then(response => {
              if(response.data.message){
                this.$snotify.error(response.data.message);
              }else{
                this.$snotify.success("Enroll  Successfully ");
              }

              this.getAcademicClassStudents();
              this.studentSearch = '';
              this.closeEnrollNow();
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
      }
    },
  },
  mounted() {
    this.getAcademicClassStudents()
    this.getAcademicClassStudents()
    this.classDetail()
  },
  computed:{
    classId() {
      return this.$route.params.classId;
    },
  },
  watch: {
    studentSearch(val) {
      user
          .search(val)
          .then((response) => {
            this.students = response.data.students;
          })
          .catch(err => {
            // console.log(err)
          })
          .finally(() => (this.isLoading = false))
    },
  }
}
</script>